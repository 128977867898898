import React from "react";
import PropTypes from "prop-types";
import "./css/layout.css";
import "./css/custom.css";
import Header from "./header";
import SEO from "./seo";
import Footer from "./footer";

const Wrapper = ({ children }) => (
    <React.Fragment>
        <SEO title="Docaposte Agile-IT" keywords={[`Docaposte`, `Docapost`, `agility`, `agilité`, `Poste`]} />
        <Header />
        {children}
        <Footer />
    </React.Fragment>
);

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Wrapper;
