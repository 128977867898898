import React from "react";
import styled from "styled-components";
import variable from "./variable";
import arrow from "../images/arrowbox.svg";

class scrollUp extends React.Component {
    state = {
        Hidescrollup: false,
    };

    componentDidMount() {
        if (document.body.clientHeight < 1400) {
            this.setState({ Hidescrollup: true });
        }
    }

    scrollTop = () => {
        window.scrollTo(0, 0);
    };

    render() {
        const { Hidescrollup } = this.state;
        return (
            <ScrollUp onClick={this.scrollTop} style={Hidescrollup ? { display: "none" } : null}>
                <img src={arrow} />
            </ScrollUp>
        );
    }
}
const ScrollUp = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;

    width: 60px;
    height: 40px;

    border: 1px solid ${variable.secondGrey};
    img {
        width: 40px;
    }
`;

export default scrollUp;
