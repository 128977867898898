import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import variable from "./variable";
import LogoBase from "../images/logo-poste.svg";
import facebook from "../images/facebook-logo.svg";
import twitter from "../images/twitter-logo.svg";
import linkedin from "../images/linkedin-logo.svg";
import Wrapper from "./wrapper";
import ScrollUp from "./scrollup";

const Footer = () => (
    <FooterContainer>
        <Wrapper>
            <Container>
                <div className="entreprise">
                    <Link to="/">
                        <img src={LogoBase} alt="Logo" />
                    </Link>
                    <p>
                        DOCAPOSTE AGILE-IT, une société de DOCAPOSTE, Groupe La Poste
                        <br />
                        <Link to="/Mentions" className="mentions">
                            Mentions Légales
                        </Link>
                    </p>
                </div>

                <div className="flex-container">
                    <div className="adresse">
                        <h5>Adresse :</h5>
                        <p>1 et 2 bis Rue Antoine Laurent Lavoisier 76300 Sotteville-lés-Rouen</p>
                    </div>
                    <div className="folow">
                        <h5>Nous suivre :</h5>
                        <div className="sociaux">
                            <a
                                href="https://www.facebook.com/agility.captain"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="social-container" style={{ marginLeft: "0px" }}>
                                    <img src={facebook} alt="facebook" />
                                </div>
                            </a>
                            <a href="https://twitter.com/DocapostAgility" target="_blank" rel="noopener noreferrer">
                                <div className="social-container">
                                    <img src={twitter} alt="twitter" />
                                </div>
                            </a>
                            <a
                                href="https://fr.linkedin.com/company/docapostagility"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="social-container">
                                    <img src={linkedin} alt="linkedin" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="endFooter">
                <p className="rights">© {new Date().getFullYear()}, Tous droits réservés</p>
                <ScrollUp />
            </div>
        </Wrapper>
    </FooterContainer>
);
const FooterContainer = styled.footer`
    color: ${variable.lightGrey};
    background-color: ${variable.darkGrey};
    margin-top: 80px;
    border-right: 10px solid ${variable.lightGrey};
    border-bottom: 10px solid ${variable.lightGrey};
    border-left: 10px solid ${variable.lightGrey};
    padding: 20px;
    .rights {
        color: ${variable.secondGrey};
        margin-bottom: 0;
        font-size: 14px;
    }
    .mentions {
        color: ${variable.lightGrey};
        font-weight: 500;
    }
    .endFooter {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    @media screen and (max-width: 768px) {
        margin-top: 40px;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    h5 {
        margin-top: 0;
        margin-bottom: 5px;
        color: ${variable.secondGrey};
    }
    .flex-container {
        display: flex;
        justify-content: space-between;
        > div {
            max-width: 50%;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            > div {
                max-width: 100%;
            }
        }
    }
    .sociaux {
        display: flex;
        margin-bottom: 10px;
        padding-top: 2px;
        .social-container {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-left: 20px;
            border: 1px solid #f5f5f5;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 18px;
        }
    }
    p {
        color: ${variable.lightGrey};
        margin-top: 0px;
    }

    > div {
        max-width: 50%;
    }
    .entreprise {
        img {
            width: 45px;
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        > div {
            max-width: 100%;
            margin-top: 10px;
        }
    }
`;
export default Footer;
