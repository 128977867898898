export default {
    marginBase: "20px",
    transition: "0.2s",
    boxShadow: " 0 3px 4px rgba(0, 0, 255, 0.01), 0 3px 4px rgba(0, 0, 255, 0.05)",
    // Colors
    primaryColor: "#0000ff",
    secondaryColor: "#ffc617",
    bodyColor: "#706f6f",
    lightGrey: "#f5f5f5",
    secondGrey: "#bab8b8",
    darkGrey: "#585858",
    lightPrimary: "#D0D0FF",
    lightSecondary: "#fff9e9"
};
