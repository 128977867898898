import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import LogoBase from "../images/logo-baseline.svg";
import variable from "./variable";
import Wrapper from "./wrapper";

const Menu = [
    {
        name: "Compétences",
        url: "/Competences/",
    },
    {
        name: "Méthode agile",
        url: "/Agilite/",
    },

    {
        name: "Équipe",
        url: "/Equipe/",
    },

    {
        name: "Réalisations",
        url: "/Realisation/",
    },
    {
        name: "Contact",
        url: "/Contact/",
    },
];

class Header extends React.Component {
    state = {
        active: false,
    };

    toggleClass = () => {
        const { active } = this.state;
        this.setState({ active: !active });
        if (window.innerWidth <= 992) {
            document.getElementsByTagName("body")[0].classList.toggle("stop-scrolling");
        }
    };

    logoClick = () => {
        const { active } = this.state;
        if (window.location.pathname === "/" && active) {
            this.toggleClass();
        }
        this.removeScroll();
    };

    handleClick = url => () => {
        if (url === window.location.pathname) {
            this.toggleClass();
        }
        this.removeScroll();
    };

    removeScroll() {
        const { active } = this.state;
        if (window.innerWidth <= 992 && active) {
            document.getElementsByTagName("body")[0].classList.remove("stop-scrolling");
        }
    }

    render() {
        const { active } = this.state;
        const open = active ? "is-open" : "";
        return (
            <Wrapper>
                <Container>
                    <div className="logo">
                        <Link to="/" onClick={this.logoClick}>
                            <img src={LogoBase} alt="Logo" />
                        </Link>
                    </div>
                    <MenuContainer>
                        <nav className={`main-nav ${open}`}>
                            <ul className={`ul ${open}`}>
                                {Menu.map(MenuItem => {
                                    return (
                                        <li key={MenuItem.name}>
                                            <Link to={MenuItem.url} onClick={this.handleClick(MenuItem.url)}>
                                                {MenuItem.name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                        <div onClick={this.toggleClass} className={`open-main-nav ${open}`}>
                            <div className={`wrapper-menu ${open}`}>
                                <div className="line-menu half start" />
                                <div className="line-menu" />
                                <div className="line-menu half end" />
                            </div>
                            <span className="menu-text">Menu</span>
                        </div>
                    </MenuContainer>
                </Container>
            </Wrapper>
        );
    }
}
const Container = styled.div`
    display: flex;
    padding-bottom: ${variable.marginBase};
    padding-top: ${variable.marginBase};
    li {
        display: inline-block;
        margin-left: 30px;
    }
    .logo {
        z-index: 101;
        img {
            width: 160px;
        }
    }
    @media screen and (max-width: 600px) {
        padding: 10px 0;
        .logo {
            img {
                width: 130px;
            }
        }
    }
`;
const MenuContainer = styled.div`
    display: flex;
    align-items: flex-end;
    font-weight: 500;
    flex: 1;
    justify-content: flex-end;
    li a {
        color: #706f6f;
        position: relative;
    }
    a:hover {
        color: ${variable.primaryColor};
        transition: ${variable.transition};
    }
    a:hover:before {
        color: ${variable.primaryColor};
        content: "";
        background: #ffc617;
        position: absolute;
        z-index: -1;
        bottom: -1px;
        right: -3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .open-main-nav {
        display: none;
    }
    @media screen and (max-width: 992px) {
        .main-nav {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            text-align: center;
            background: transparent;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
            font-size: 20px;
            transition: all 0.425s;
        }
        .main-nav.is-open {
            background: #fff;
            display: block;
            opacity: 1;
            z-index: 100;
            visibility: visible;
        }

        .main-nav ul {
            display: inline-flex;
            margin-top: 50px;
            opacity: 0;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }
        .ul.is-open {
            opacity: 1;
        }

        .main-nav li {
            margin: 0 0 15px 0;
        }
        .main-nav a {
            opacity: 0;
            transform: translateY(-10px);
        }

        .main-nav.is-open a {
            opacity: 1;
            transform: translateY(0);
        }
        .main-nav li:nth-child(1) a {
            transition: all 275ms 175ms;
        }
        .main-nav li:nth-child(2) a {
            transition: all 275ms 225ms;
        }
        .main-nav li:nth-child(3) a {
            transition: all 275ms 275ms;
        }
        .main-nav li:nth-child(4) a {
            transition: all 275ms 325ms;
        }
        .main-nav li:nth-child(5) a {
            transition: all 275ms 375ms;
        }
        .main-nav li:nth-child(6) a {
            transition: all 275ms 425ms;
        }
        .main-nav li:nth-child(7) a {
            transition: all 275ms 450ms;
        }
        .main-nav li:nth-child(8) a {
            transition: all 275ms 475ms;
        }

        .open-main-nav {
            z-index: 1000;
            background: none;
            border: 0;
            cursor: pointer;
        }

        .wrapper-menu {
            width: 35px;
            height: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            transition: transform 330ms ease-out;
        }

        .wrapper-menu.is-open {
            transform: rotate(-45deg);
        }

        .line-menu {
            background-color: ${variable.primaryColor};
            width: 100%;
            height: 3px;
        }

        .line-menu.half {
            width: 50%;
        }

        .line-menu.start {
            transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
            transform-origin: right;
        }

        .is-open .line-menu.start {
            transform: rotate(-90deg) translateX(9px);
        }
        .line-menu.end {
            align-self: flex-end;
            transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
            transform-origin: left;
        }

        .is-open .line-menu.end {
            transform: rotate(-90deg) translateX(-10px);
        }

        .menu-text {
            display: block;
            letter-spacing: 0.05em;
            margin-top: 2px;
            font-weight: 500;
            text-align: center;
            color: ${variable.primaryColor};
        }
        .open-main-nav {
            display: block;
        }
        ul {
            padding: 0;
        }
    }
`;

export default Header;
